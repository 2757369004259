import { Typography } from '@/shared';
import { pushSlug } from '@/shared/helpers/url';
import { getMindentityDataURL } from '@/shared/lib/Mindentity.script.mjs';
import { useBreakpoints } from '@/shared/providers/Device';
import { useMicrocopy } from '@/shared/providers/Microcopy/MicrocopyHook';
import { ServiceStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { Icon } from '@/shared/ui/Icon/Icon';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import styles from './ServiceListing.module.scss';
import { cardSuit } from './ServiceListingCore';
import Link from 'next/link';

interface ServiceCard {
    element: ServiceStoryblok;
    theme?: 'dark' | 'light';
    isOpen: boolean;
    handleCardSide: (id: string) => void;
    isServicePage: boolean;
    slug?: string;
}

const ServiceCard: React.FC<ServiceCard> = ({
    element,
    isOpen,
    theme = 'dark',
    handleCardSide,
    isServicePage,
    slug,
}) => {
    const color = theme === 'dark' ? '#E59900' : '#F2B600';
    const { microcopy } = useMicrocopy();
    const { _uid, name, type, mindentity, backOfCard } = (element?.content ?? element) || {};
    const [isClient, setIsClient] = useState(false);
    const router = useRouter();
    const { isXXSmall, isExtraSmall, isSmall, isMedium } = useBreakpoints();
    const isMobile = isXXSmall || isExtraSmall || isSmall;

    useEffect(() => {
        setIsClient(true);
    }, []);
    if (!(element?.content ?? element) || !type) return null;
    const options = {
        margin: 0,
        mode: 'string',
        input: name,
        foregroundColor: color,
        backgroundColor: theme === 'dark' ? '#1A1716' : '#E1E3E5',
        whiteSpace: 20,
    };

    function click(e: React.MouseEvent<HTMLDivElement>, element: ServiceStoryblok) {
        pushSlug(slug ?? element.full_slug, router.locale);
    }

    return (
        <div
            className={clsx(styles.card, { [styles.flipped]: isOpen, [styles.light]: theme === 'light' })}
            onMouseEnter={() => (isMobile ? undefined : handleCardSide(_uid))}
            onMouseLeave={() => (isMobile ? undefined : handleCardSide(_uid))}
        >
            <div className={styles.frontCard} onClick={() => (!isMobile ? undefined : handleCardSide(_uid))}>
                <Icon name={cardSuit(type?.name)} className={styles.topIcon} style={{ color: color }} />
                <div className={styles.infos}>
                    <div className={styles.preTitleWrap}>
                        <Typography.Small className={styles.preTitle}>{type?.name}</Typography.Small>
                    </div>
                    <Typography.H2 className={styles.name}>{name}</Typography.H2>
                </div>

                <div className={styles.bottomIconWrap}>
                    <Icon name={cardSuit(type?.name)} style={{ color: color }} />
                </div>
            </div>

            {!isServicePage && element?.full_slug ? (
                <Link href={element.full_slug} className={styles.backCard}>
                    <div className={styles.mindnentityWrap}>
                        {isClient && (
                            <picture>
                                <img
                                    src={getMindentityDataURL(options as any)}
                                    alt={`A unique code composed of squares, semi-circles, and rounded edges that is the Mindentity representation of our service, ${element.title}.`}
                                    height={168}
                                    className={styles.mask}
                                />
                            </picture>
                        )}
                    </div>

                    <div className={styles.backDescription}>{backOfCard}</div>

                    <div className={styles.actionButton} style={{ backgroundColor: color }}>
                        {microcopy.openCardButton}
                        <Icon name="arrow_forward" />
                    </div>
                </Link>
            ) : (
                <div className={styles.backCard}>
                    <div className={styles.mindnentityWrap}>
                        {isClient && (
                            <picture>
                                <img
                                    src={getMindentityDataURL(options as any)}
                                    alt="Mindentity"
                                    height={168}
                                    className={styles.mask}
                                />
                            </picture>
                        )}
                    </div>

                    <div className={styles.backDescription}>{backOfCard}</div>
                </div>
            )}
        </div>
    );
};

export default ServiceCard;
